import "../layout/vendors/mdi/css/materialdesignicons.min.css"
import "../layout/vendors/css/vendor.bundle.base.css"
import "../layout/vendors/flag-icon-css/css/flag-icon.min.css"
import "../layout/css/vertical-layout-light/style.css"

import {Outlet} from "react-router-dom";

const FormTemplate = () => {
    return <div className="container-scroller">
        <div className="main-panel">
            <div className="content-wrapper">
                <Outlet/>
            </div>
        </div>
    </div>;
}
export default FormTemplate;