import PollList from "./pages/poll/pollList";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import MainPage from "./pages/mainPage";
import PollReport from "./pages/poll/pollReport";
import PollEdit from "./pages/poll/pollEdit";
import PollPublish from "./pages/poll/pollPublish";
import PollSettings from "./pages/poll/pollSettings";
import PollPublished from "./pages/poll/pollPublished";
import MainTemplate from "./template/mainTemplate";
import FormTemplate from "./template/formTemplate";


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/form" element={<FormTemplate/>}>
                    <Route path=":pollId" element={<PollPublished/>}/>
                </Route>
                <Route path="/" element={<MainTemplate/>}>
                    <Route index element={<MainPage/>}/>
                    <Route path="polls">
                        <Route index element={<PollList/>}/>
                        <Route path=":pollId/edit" element={<PollEdit/>}/>
                        <Route path=":pollId/report" element={<PollReport/>}/>
                        <Route path=":pollId/settings" element={<PollSettings/>}/>
                        <Route path=":pollId/publish" element={<PollPublish/>}/>
                    </Route>
                    <Route path="*" element={<>404 not found</>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
