import React from "react"
import {useParams} from "react-router-dom";
import Breadcrumbs from "../../components/breadcrumbs";
import PollMenu from "../../components/pollMenu";
import {getPollById} from "../../store/polls";
import {useSelector} from "react-redux";

const PollSettings = () => {
    const params = useParams();
    const poll = useSelector(getPollById(params.pollId));
    return (<>
        <Breadcrumbs items={[{title: "Главная", url: "/"}, {title: "Мои опросы", url: "/polls/"}, {title: poll?.name}]
        }></Breadcrumbs>
        <PollMenu pollId={params.pollId}/>
    </>);
}

export default PollSettings;