import React from "react";
import {Link, useMatch} from "react-router-dom";

const PollMenu = ({pollId}) => {
    const isEdit = useMatch({path: '/polls/' + pollId + '/edit', end: false});
    const isReport = useMatch({path: '/polls/' + pollId + '/report', end: false});
    const isSettings = useMatch({path: '/polls/' + pollId + '/settings', end: false});
    const isPublish = useMatch({path: '/polls/' + pollId + '/publish', end: false});
    return <div className="border-top">
        <ul className="nav profile-navbar">
            <li className="nav-item">
                <Link className={"nav-link " + (isEdit ? "active" : "")} to={'/polls/' + pollId + '/edit'}>
                    <i className="mdi mdi mdi-checkbox-multiple-blank-outline mr-1"></i>
                    Вопросы
                </Link>
            </li>
            <li className="nav-item">
                <Link className={"nav-link " + (isReport ? "active" : "")} to={'/polls/' +pollId + '/report'}>
                    <i className="mdi mdi-checkbox-multiple-marked-outline mr-1"></i>
                    Ответы
                </Link>
            </li>
            <li className="nav-item">
                <Link className={"nav-link " + (isSettings? "active" : "")} to={'/polls/' +pollId + '/settings'}>
                    <i className="mdi mdi-settings mr-1"></i>
                    Настройки
                </Link>
            </li>
            <li className="nav-item">
                <Link className={"nav-link " + (isPublish ? "active" : "")} to={'/polls/' +pollId + '/publish'}>
                    <i className="mdi mdi-eye mr-1"></i>
                    Публикация
                </Link>
            </li>
        </ul>
    </div>
}

export default PollMenu;