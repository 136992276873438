import React from "react";
import {Field} from "formik";

const PollQuestionOptions = ({type, options}) => {
    return <div >
        <label>Варианты ответов</label>
        <table className="table">
            <tbody>
            {options.rows.map(row => <tr>
                <td>
                    <input
                        name="name"
                        type="text"
                        className="form-control"
                        value={row}
                    /></td>
                <td> </td>
            </tr>)}
            </tbody>
        </table>
    </div>
}

export default PollQuestionOptions;