import httpService from './httpService'

const publishedEndPoint = 'published/'

const publishedService = {
    get: async (id) => {
        const { data } = await httpService.get(publishedEndPoint + id)
        return data
    }
}

export default publishedService