import "../layout/vendors/mdi/css/materialdesignicons.min.css"
import "../layout/vendors/css/vendor.bundle.base.css"
import "../layout/vendors/flag-icon-css/css/flag-icon.min.css"
import "../layout/css/vertical-layout-light/style.css"

import Header from "../components/header";
import SettingsPanel from "../components/settingsPanel";
import RightSideBar from "../components/rightSideBar";
import SideBar from "../components/sideBar";
import {Outlet} from "react-router-dom";
import Footer from "../components/footer";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {loadPollsList} from "../store/polls";

const MainTemplate = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadPollsList());
    }, [dispatch]);

    return <div className="container-scroller">
        <Header></Header>
        <div className="container-fluid page-body-wrapper">
            <SettingsPanel></SettingsPanel>
            <RightSideBar></RightSideBar>
            <SideBar></SideBar>
            <div className="main-panel">
                <div className="content-wrapper">
                    <Outlet/>
                </div>
                <Footer></Footer>
            </div>
        </div>
    </div>;
}
export default MainTemplate;