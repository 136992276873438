import httpService from './httpService'

const questionEndPoint = 'question'

const questionsService = {
    update: async (pollId, id, content) => {
        const {data} = await httpService.post(`${questionEndPoint}/${pollId}/${id}`, content)
        return data
    },
    get: async (pollId, id) => {
        const {data} = await httpService.get(`${questionEndPoint}/${pollId}/${id}`)
        return data
    },
    fetchAll: async (pollId) => {
        const {data} = await httpService.get(`${questionEndPoint}/${pollId}`)
        return data
    },
    create: async (pollId, content) => {
        const {data} = await httpService.post(`${questionEndPoint}/${pollId}`, content)
        return data
    },
    delete: async (pollId, id) => {
        const {data} = await httpService.delete(`${questionEndPoint}/${pollId}/${id}`)
        return data
    }
}

export default questionsService