import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteQuestion, moveQuestion, updateQuestion} from "../../store/questions";
import {Field, Form, Formik} from "formik";
import * as yup from "yup";
import {useDebounce} from "../../hooks/useDebounce";
import AutoSubmit from "../base/autoSubmit";
import {getQuestionTypes, loadQuestionTypesList} from "../../store/questionTypes";
import PollQuestionOptions from "./pollQuestionOptions";

const PollQuestion = ({pollId, question}) => {
    const types = useSelector(getQuestionTypes());
    const dispatch = useDispatch();
    const deleteHandler = () => {
        dispatch(deleteQuestion(pollId, question.id));
    }
    const moveDownHandler = () => {
        dispatch(moveQuestion("down", pollId, question.id));
    }
    const moveUpHandler = () => {
        dispatch(moveQuestion("up", pollId, question.id));
    }

    const debouncedSaveHandler = useDebounce((data) => {
        dispatch(updateQuestion(pollId, {...data, id: question.id}));
    });

    const signInSchema = yup.object().shape({
        name: yup.string().required('Поле обязательное для заполнения')
    });

    useEffect(() => {
        dispatch(loadQuestionTypesList());
    }, [])

    const formData = {
        name: question.name || "",
        description: question.description || "",
        type_id: question.type_id || 1,
        options: {
            rows: [
                'Вариант 1',
                'Вариант 2',
                'Вариант 3',
            ]
        }
    }

    return <div className="col-12 stretch-card">
        <div className="card">
            <div className="card-body">
                <div className="float-right" onClick={deleteHandler}>
                    <i className="remove mdi mdi-close-circle-outline"></i>
                </div>
                <div className="float-right" onClick={moveDownHandler}>
                    <i className="remove mdi mdi-arrow-down-bold-circle-outline"></i>
                </div>
                <div className="float-right" onClick={moveUpHandler}>
                    <i className="remove mdi mdi-arrow-up-bold-circle-outline"></i>
                </div>
                <Formik
                    initialValues={formData}
                    validationSchema={signInSchema}
                    onSubmit={debouncedSaveHandler}>
                    {({errors: formicErrors, touched}) => <Form>
                        <div className="form-group">
                            <label htmlFor={"questionName" + question.id}>Вопрос</label>
                            <Field
                                name="name"
                                type="text"
                                className="form-control"
                                id={"questionName" + question.id}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor={"questionDescription" + question.id}>Описание</label>
                            <Field
                                as="textarea"
                                name="description"
                                className="form-control"
                                rows="4"
                                id={"questionDescription" + question.id}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor={"questionType" + question.id}>Тип</label>
                            <Field
                                as="select"
                                name="type_id"
                                className="form-control"
                                id={"questionType" + question.id}
                            >
                                {types.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                            </Field>
                        </div>

                        <PollQuestionOptions type={formData.type_id} options={formData.options}/>

                        <AutoSubmit/>
                    </Form>}
                </Formik>
            </div>
        </div>
    </div>
}

export default PollQuestion;