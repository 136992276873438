import React from "react";

const Footer = () => {
    return (<footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
                          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">© 2022 <a
                              href="https://buzzz.ru/"
                              className="text-muted">Buzzz</a>. Все права защищены.</span>
            <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Сделано с <i
                className="mdi mdi-heart-outline text-primary"></i></span>
        </div>
    </footer>)
}

export default Footer;