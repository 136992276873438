import {createSlice} from '@reduxjs/toolkit'
import pollsService from '../services/pollsService'

const pollsSlice = createSlice({
    name: 'polls',
    initialState: {
        entities: null,
        isLoading: true,
        error: null,
        lastFetch: null,
        savingCount: 0,
    },
    reducers: {
        pollsRequested: (state) => {
            state.isLoading = true
        },
        pollsReceived: (state, action) => {
            state.entities = action.payload
            state.isLoading = false
            state.lastFetch = Date.now()
        },
        pollsRequestFiled: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
        createPollRequested: (state) => {
            state.isLoading = true
        },
        createPollReceived: (state, action) => {
            state.entities.push(action.payload);
            state.isLoading = false
        },
        createPollRequestFiled: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
        deletePollRequested: (state) => {
            state.isLoading = true
        },
        deletePollReceived: (state, action) => {
            console.log('action.payload', action.payload)
            state.entities = state.entities.filter((item) => item.uuid !== action.payload.uuid);
        },
        deletePollRequestFiled: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
        updatePollRequested: (state) => {
            state.isLoading = true
            state.savingCount++;
        },
        updatePollReceived: (state, action) => {
            state.entities = state.entities.map((item) => {
                if (item.uuid === action.payload.uuid) {
                    return {...item, ...action.payload};
                }
                return item;
            });
            state.isLoading = false;
            state.savingCount--;
        },
        updatePollRequestFiled: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
            state.savingCount--;
        },
    }
})
const {reducer: pollsReducer, actions} = pollsSlice
const {
    pollsRequested,
    pollsReceived,
    pollsRequestFiled,
    createPollRequested,
    createPollReceived,
    createPollRequestFiled,
    deletePollRequested,
    deletePollReceived,
    deletePollRequestFiled,
    updatePollRequested,
    updatePollReceived,
    updatePollRequestFiled
} = actions

function isOutdated(date) {
    if (Date.now() - date > 10 * 60 * 100) return true
    return false
}

export const loadPollsList = () => async (dispatch, getState) => {
    const {lastFetch} = getState().polls
    if (isOutdated(lastFetch)) {
        dispatch(pollsRequested());
        try {
            const {content} = await pollsService.fetchAll();
            dispatch(pollsReceived(content));
        } catch (error) {
            dispatch(pollsRequestFiled(error.message));
        }
    }
}

export const createPoll = (callback) => async (dispatch, getState) => {
    dispatch(createPollRequested());
    try {
        const {content} = await pollsService.create();
        dispatch(createPollReceived(content));
        if (typeof callback === 'function') {
            callback(content);
        }
    } catch (error) {
        dispatch(createPollRequestFiled(error.message));
    }
}

export const deletePoll = (uuid) => async (dispatch, getState) => {
    dispatch(deletePollRequested());
    try {
        const {content} = await pollsService.delete(uuid);
        dispatch(deletePollReceived({uuid}));
    } catch (error) {
        dispatch(deletePollRequestFiled(error.message));
    }
}

export const updatePoll = (data) => async (dispatch, getState) => {
    dispatch(updatePollRequested());
    try {
        const {content} = await pollsService.update(data.uuid, data);
        dispatch(updatePollReceived(data));
    } catch (error) {
        dispatch(updatePollRequestFiled(error.message));
    }
}

export const getPolls = () => (state) => state.polls.entities;
export const getPollsLoadingStatus = () => (state) => state.polls.isLoading;
export const getPollsSavingCount = () => (state) => state.polls.savingCount;

export const getPollById = (uuid) => (state) => {
    if (state.polls.entities) {
        return state.polls.entities.find((item) => item.uuid === uuid);
    }
    return undefined;
}
export default pollsReducer;