import React, {useState} from "react";
import {Link, useMatch, useResolvedPath} from "react-router-dom";

const SideBarLink = ({children, icon, to, label}) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({path: resolved.pathname, end: (children ? false : true)});
    const href = children ? "#" : to;
    const [show, setShow] = useState(match);
    return (
        <li className={"nav-item " + (match ? "active" : "")}>
            <Link className={"nav-link " + (match ? "active" : "")} to={href} aria-expanded={(show ? "true" : "false")}
                  onClick={() => {
                      setShow(!show)
                  }}>
                {icon ? <i className={"mdi menu-icon " + icon}></i> : ""}
                {icon ? <span className="menu-title">{label}</span> : label}
                {children ? <i className="menu-arrow"></i> : ""}
            </Link>
            {children ? <div className={"collapse " + (show ? "show" : "")}>
                <ul className="nav flex-column sub-menu">{children}</ul>
            </div> : ""}
        </li>
    );
}

export default SideBarLink;