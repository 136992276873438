import React from "react";
import {Link} from "react-router-dom";
import SideBarLink from "./base/sideBarLink";

const SideBar = () => {
    return (
        <nav className="sidebar sidebar-offcanvas">
            <ul className="nav">
                <SideBarLink to="/" icon="mdi-airplay" label="Сводка"></SideBarLink>
                <SideBarLink to="/polls/" icon="mdi-clipboard-text-outline" label="Мои опросы"></SideBarLink>
                <SideBarLink to="/example" icon="mdi-cube-outline" label="Example">
                    <SideBarLink to="/example/sub/" label="SubExample"></SideBarLink>
                    <SideBarLink to="/example/sub2/" label="SubExample 2"></SideBarLink>
                </SideBarLink>
            </ul>
        </nav>
    );
}

export default SideBar;