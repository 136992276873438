import {useSelector} from "react-redux";
import {getPollsSavingCount} from "../../store/polls";
import {useEffect, useRef, useState} from "react";
import {useDebounce} from "../../hooks/useDebounce";
import {getQuestionsSavingCount} from "../../store/questions";

const SavingStatus = () => {
    const pollsSavingCount = useSelector(getPollsSavingCount());
    const questionsSavingCount = useSelector(getQuestionsSavingCount());
    const ref = useRef(null);
    const [statusText, setStatusText] = useState('');
    useEffect(() => {
        if (pollsSavingCount > 0 || questionsSavingCount > 0) {
            setStatusText('Сохранение...');
            if (ref.current) {
                clearTimeout(ref.current);
            }
        } else if (statusText) {
            ref.current = setTimeout(() => {
                setStatusText('Изменения сохранены');
                ref.current = setTimeout(() => {
                    setStatusText('');
                    ref.current = undefined;
                }, 3000);
            }, 1000);
        }

    }, [pollsSavingCount, questionsSavingCount]);

    return <>
        {statusText}
    </>;
}

export default SavingStatus;