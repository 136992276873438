import {combineReducers, configureStore} from '@reduxjs/toolkit';
import pollsReducer from './polls';
import questionsReducer from "./questions";
import questionTypesReducer from "./questionTypes";
import publishedReducer from "./published";

const rootReducer = combineReducers({
    polls: pollsReducer,
    questions: questionsReducer,
    questionTypes: questionTypesReducer,
    published: publishedReducer,
});

export function createStore() {
    return configureStore({
        reducer: rootReducer
    })
}