import httpService from './httpService'

const questionEndPoint = 'questionType'

const questionsService = {
    get: async (id) => {
        const {data} = await httpService.get(`${questionEndPoint}/${id}`)
        return data
    },
    fetchAll: async () => {
        const {data} = await httpService.get(`${questionEndPoint}/`)
        return data
    }
}

export default questionsService