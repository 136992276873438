import React from "react";
import SavingStatus from "./base/savingStatus";

const Header = () => {
    return (
        <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center">
                <a className="navbar-brand brand-logo" href="index.html">
                    <img src="/images/logo.svg" alt="logo"/></a>
                <a className="navbar-brand brand-logo-mini" href="index.html">
                    <img src="/images/logo-mini.svg" alt="logo"/></a>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                <button className="navbar-toggler navbar-toggler align-self-center" type="button"
                        data-toggle="minimize">
                    <span className="mdi mdi-menu"></span>
                </button>
                <div className="navbar-nav navbar-nav-left pl-lg-3 text-info">
                    <SavingStatus/>
                </div>
                <ul className="navbar-nav navbar-nav-right">
                    <li className="nav-item dropdown">
                        <a className="nav-link count-indicator dropdown-toggle d-flex justify-content-center align-items-center"
                           id="messageDropdown" href="#" data-toggle="dropdown">
                            <i className="mdi mdi-email-outline mx-0"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                             aria-labelledby="messageDropdown">
                            <p className="mb-0 font-weight-normal float-left dropdown-header">Messages</p>
                            <a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <img src="https://via.placeholder.com/36x36" alt="image"
                                         className="profile-pic"/>
                                </div>
                                <div className="preview-item-content flex-grow">
                                    <h6 className="preview-subject ellipsis font-weight-normal">David Grey
                                    </h6>
                                    <p className="font-weight-light small-text text-muted mb-0">
                                        The meeting is cancelled
                                    </p>
                                </div>
                            </a>
                            <a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <img src="https://via.placeholder.com/36x36" alt="image"
                                         className="profile-pic"/>
                                </div>
                                <div className="preview-item-content flex-grow">
                                    <h6 className="preview-subject ellipsis font-weight-normal">Tim Cook
                                    </h6>
                                    <p className="font-weight-light small-text text-muted mb-0">
                                        New product launch
                                    </p>
                                </div>
                            </a>
                            <a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <img src="https://via.placeholder.com/36x36" alt="image"
                                         className="profile-pic"/>
                                </div>
                                <div className="preview-item-content flex-grow">
                                    <h6 className="preview-subject ellipsis font-weight-normal"> Johnson
                                    </h6>
                                    <p className="font-weight-light small-text text-muted mb-0">
                                        Upcoming board meeting
                                    </p>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center"
                           id="notificationDropdown" href="#" data-toggle="dropdown">
                            <i className="mdi mdi-bell-outline mx-0"></i>
                            <span className="count"></span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                             aria-labelledby="notificationDropdown">
                            <p className="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
                            <a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-success">
                                        <i className="mdi mdi-information mx-0"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <h6 className="preview-subject font-weight-normal">Application Error</h6>
                                    <p className="font-weight-light small-text mb-0 text-muted">
                                        Just now
                                    </p>
                                </div>
                            </a>
                            <a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-warning">
                                        <i className="mdi mdi-settings mx-0"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <h6 className="preview-subject font-weight-normal">Settings</h6>
                                    <p className="font-weight-light small-text mb-0 text-muted">
                                        Private message
                                    </p>
                                </div>
                            </a>
                            <a className="dropdown-item preview-item">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-info">
                                        <i className="mdi mdi-account-box mx-0"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <h6 className="preview-subject font-weight-normal">New user registration</h6>
                                    <p className="font-weight-light small-text mb-0 text-muted">
                                        2 days ago
                                    </p>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li className="nav-item nav-profile dropdown">
                        <a className="nav-link" href="#" data-toggle="dropdown" id="profileDropdown">
                            <img src="https://via.placeholder.com/30x30" alt="profile"/>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right navbar-dropdown"
                             aria-labelledby="profileDropdown">
                            <a className="dropdown-item">
                                <i className="mdi mdi-settings "></i>
                                Settings
                            </a>
                            <a className="dropdown-item">
                                <i className="mdi mdi-logout"></i>
                                Logout
                            </a>
                        </div>
                    </li>
                    <li className="nav-item nav-settings d-none d-lg-flex">
                        <a className="nav-link" href="#">
                            <i className="mdi mdi-dots-horizontal"></i>
                        </a>
                    </li>
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                        data-toggle="offcanvas">
                    <span className="mdi mdi-menu"></span>
                </button>
            </div>
        </nav>
    );
}

export default Header;