import React, {useCallback, useState} from "react";
import _ from "lodash";

export const useDebounce = (callback, wait = 1000) => {
    const setDebouncedState = (_val) => {
        debounce(_val);
    };
    const debounce = useCallback(
        _.debounce((_prop) => {
            callback(_prop);
        }, wait),
        []
    );

    return setDebouncedState;
};