import React from "react"
import Breadcrumbs from "../../components/breadcrumbs";
import PollMenu from "../../components/pollMenu";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getPollById, updatePoll} from "../../store/polls";
import {Field, Form, Formik} from "formik";
import {useDebounce} from "../../hooks/useDebounce";
import {updateQuestion} from "../../store/questions";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import AutoSubmit from "../../components/base/autoSubmit";

const PollPublish = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const poll = useSelector(getPollById(params.pollId));
    const debouncedSaveHandler = useDebounce((data) => {
        console.log('Сохранение формы');
        dispatch(updatePoll({published: data.published ? 1 : 0, uuid: params.pollId}));
    });
    if (!poll) return "Loading..."

    const formData = {
        published: Boolean(poll?.published),
    }
    const publishUrl = 'https://forms.buzzz.ru/form/' + poll.uuid;

    return (
        <>
            <Breadcrumbs
                items={[{title: "Главная", url: "/"}, {title: "Мои опросы", url: "/polls/"}, , {title: poll?.name}]
                }></Breadcrumbs>
            <PollMenu pollId={params.pollId}/>
            <div className="col-md-12 grid-margin">
                <Formik
                    initialValues={formData}
                    onSubmit={debouncedSaveHandler}>
                    {({errors: formicErrors, touched}) => <Form>
                        <div className="card">
                            <div className="card-body">
                                <div className="form-group row mb-0">
                                    <div className="col-12">
                                        <p className="mb-2">Опубликовать</p>
                                        <label className="toggle-switch">
                                            <Field
                                                name="published"
                                                type="checkbox"
                                            />
                                            <span className="toggle-slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group row mb-0">
                                    <div className="col">
                                        <label htmlFor="publicLink">Публичная ссылка</label>
                                        <div className="input-group">
                                            <input type="text"
                                                   className="form-control"
                                                   id="publicLink"
                                                   value={publishUrl}
                                                   readOnly={true}
                                            />
                                            <div className="input-group-append">
                                                <CopyToClipboard text={'https://test.com'}>
                                                    <button className="btn btn-sm btn-primary" type="button"
                                                    >Копировать
                                                    </button>
                                                </CopyToClipboard>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <AutoSubmit/>
                    </Form>}
                </Formik>

            </div>
        </>
    );
}

export default PollPublish;