import React, {useState} from "react"
import Breadcrumbs from "../../components/breadcrumbs";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createPoll, deletePoll, getPolls, getPollsLoadingStatus} from "../../store/polls";

const PollList = () => {
    const [filter, setFilter] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const rows = useSelector(getPolls());
    const isLoading = useSelector(getPollsLoadingStatus());
    console.log('isLoading', isLoading);
    if (!rows || isLoading) return "Loading...";

    const newPollClickHandle = async (event) => {
        dispatch(createPoll(({uuid}) => {
            navigate(`/polls/${uuid}/edit/`);
        }));
    }
    const deletePollClickHandle = async (uuid) => {
        dispatch(deletePoll(uuid));
    }
    const searchHandle = (event) => {
        setFilter(event.target.value);
    }

    // Фильтрация
    const filteredRows = filter ? rows.filter((row) => row?.name.indexOf(filter) !== -1) : rows;

    return (
        <>
            <Breadcrumbs items={[{title: "Главная", url: "/"}, {title: "Мои опросы"}]}></Breadcrumbs>
            <div className="row">
                <div className="col-xl-8 stretch-card grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h6 className="card-title">Мои опросы </h6>
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <button onClick={newPollClickHandle}
                                            className="btn btn-outline-primary btn-icon-text btn-sm">
                                        Новый опрос
                                        <i className="mdi mdi-plus-circle-outline btn-icon-append"></i>
                                    </button>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="text-right"><label>
                                        <input type="search"
                                               className="form-control"
                                               placeholder="Поиск"
                                               aria-controls="order-listing"
                                               onChange={searchHandle}
                                        />
                                    </label>
                                    </div>
                                </div>
                            </div>
                            <p className="mb-2 text-muted">
                            </p>
                            <div className="table-responsive table-hover">
                                <table className="table card-table-one">
                                    <thead>
                                    <tr>
                                        <th>Опрос</th>
                                        <th>Вопросы</th>
                                        <th>Ответы</th>
                                        <th>Завершение</th>
                                        <th>Обновлено</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredRows.map((row) => (<tr key={row.uuid}>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <Link to={row.uuid + "/edit"}><i
                                                    className="mdi mdi-square-edit-outline icon-sm text-primary"></i></Link>
                                                <Link to={row.uuid + "/report"}><p
                                                    className="mb-0 ml-1 text-dark cursor-pointer">
                                                    <strong>{row.name}</strong></p></Link>
                                            </div>
                                        </td>
                                        <td>10</td>
                                        <td>2</td>
                                        <td>15.47%</td>
                                        <td>20.05.2022</td>
                                        <td>
                                            <div className="list-wrapper float-right"
                                                 onClick={_ => deletePollClickHandle(row.uuid)}>
                                                <i className="remove mdi mdi-close-circle-outline"></i>
                                            </div>
                                        </td>
                                    </tr>))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PollList;