import {createSlice} from '@reduxjs/toolkit'
import pollsService from '../services/pollsService'
import publishedService from "../services/publishedService";

const pollsSlice = createSlice({
    name: 'published',
    initialState: {
        entity: {},
        isLoading: true,
        error: null
    },
    reducers: {
        pollRequested: (state) => {
            state.isLoading = true
        },
        pollReceived: (state, action) => {
            state.entity = action.payload;
            state.isLoading = false;
        },
        pollRequestFiled: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        }
    }
})
const {reducer: publishedReducer, actions} = pollsSlice
const {
    pollRequested,
    pollReceived,
    pollRequestFiled,
} = actions

export const loadPublishedPollById = (uuid) => async (dispatch, getState) => {
    dispatch(pollRequested());
    try {
        const {content} = await publishedService.get(uuid);
        dispatch(pollReceived(content));
    } catch (error) {
        dispatch(pollRequestFiled(error.message));
    }

}


export const getPublishedPoll = () => (state) => state.published.entity;
export const getPublishedPollLoadingStatus = () => (state) => state.published.isLoading;

export default publishedReducer;