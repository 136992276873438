import {useFormikContext} from "formik";
import {useEffect, useRef, useState} from "react";

const AutoSubmit = () => {
    const {values, submitForm} = useFormikContext();
    const [isInit, setInit] = useState(false);
    useEffect(() => {
        if (!isInit) {
            setInit(true);
        } else {
            submitForm();
        }
    }, [values, submitForm]);
    return null;
};

export default AutoSubmit;