import {createSlice} from '@reduxjs/toolkit'
import questionsService from '../services/questionsService'

const questionsSlice = createSlice({
    name: 'questions',
    initialState: {
        polls: {},
        error: null,
        savingCount: 0,
    },
    reducers: {
        questionsRequested: (state, action) => {
            const {pollId} = action.payload;
            state.polls[pollId] = {
                isLoading: true
            }
        },
        questionsReceived: (state, action) => {
            const {pollId, content} = action.payload;
            state.polls[pollId] = {
                entities: content,
                isLoading: false,
                lastFetch: Date.now()
            }
        },
        questionsRequestFiled: (state, action) => {
            const {pollId, error} = action.payload;
            state.polls[pollId].isLoading = false;
            state.error = error
        },
        createQuestionRequested: (state) => {
            state.isLoading = true
        },
        createQuestionReceived: (state, action) => {
            const {pollId, content, index} = action.payload;
            state.polls[pollId].entities.splice(index, 0, content);
        },
        createQuestionRequestFiled: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
        deleteQuestionRequested: (state, action) => {
            const {pollId} = action.payload;
            state.polls[pollId].isLoading = true;
        },
        deleteQuestionReceived: (state, action) => {
            const {pollId, id} = action.payload;
            state.polls[pollId].entities = state.polls[pollId].entities.filter((item) => item.id !== id);
        },
        deleteQuestionRequestFiled: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
        updateQuestionRequested: (state) => {
            state.isLoading = true;
            state.savingCount++;
        },
        updateQuestionReceived: (state, action) => {
            const {pollId, data} = action.payload;
            state.polls[pollId].entities = state.polls[pollId].entities.map((item) => {
                if (item.id === data.id) {
                    return {...item, ...data};
                }
                return item;
            });
            state.savingCount--;
        },
        updateQuestionRequestFiled: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
            state.savingCount--;
        },
        questionMoved: (state, action) => {
            const {pollId, direction, id} = action.payload;
            const currentIndex = state.polls[pollId].entities.findIndex(item => item.id === id);
            const currentItem = state.polls[pollId].entities.find(item => item.id === id);
            let filtered = state.polls[pollId].entities.filter(item => item.id !== id);

            switch (direction) {
                case "up":
                    filtered.splice(currentIndex - 1, 0, currentItem);
                    state.polls[pollId].entities = filtered;
                    break;
                case "down":
                    filtered.splice(currentIndex + 1, 0, currentItem);
                    state.polls[pollId].entities = filtered;
                    break;
            }

        }
    }
})
const {reducer: questionsReducer, actions} = questionsSlice
const {
    questionsRequested,
    questionsReceived,
    questionsRequestFiled,
    createQuestionRequested,
    createQuestionReceived,
    createQuestionRequestFiled,
    deleteQuestionRequested,
    deleteQuestionReceived,
    deleteQuestionRequestFiled,
    updateQuestionRequested,
    updateQuestionReceived,
    updateQuestionRequestFiled,
    questionMoved
} = actions

function isOutdated(date) {
    date = date || null;
    if (Date.now() - date > 10 * 60 * 100) return true
    return false
}

const updateQuestionsSort = (pollId, items) => {
    console.log('updateQuestionsSort', pollId)
    console.log('items', items)
    items.forEach((item, index) => {
        questionsService.update(pollId, item.id, {sort: index + 1});
    })

}

export const loadQuestionsList = (pollId) => async (dispatch, getState) => {
    const lastFetch = getState().questions.polls[pollId]?.lastFetch;
    if (isOutdated(lastFetch)) {
        dispatch(questionsRequested({pollId}));
        try {
            const {content} = await questionsService.fetchAll(pollId);
            dispatch(questionsReceived({pollId, content}));
        } catch (error) {
            dispatch(questionsRequestFiled({pollId, error: error.message}));
        }
    }
}

export const createQuestion = (pollId, index = 0, callback) => async (dispatch, getState) => {
    dispatch(createQuestionRequested());
    try {
        const {content} = await questionsService.create(pollId);
        await dispatch(createQuestionReceived({pollId, content, index}));

        if (typeof callback === 'function') {
            callback(content);
        }
        updateQuestionsSort(pollId, getState().questions.polls[pollId].entities);
    } catch (error) {
        dispatch(createQuestionRequestFiled(error.message));
    }
}

export const deleteQuestion = (pollId, id) => async (dispatch, getState) => {
    dispatch(deleteQuestionRequested({pollId}));
    try {
        const {content} = await questionsService.delete(pollId, id);
        dispatch(deleteQuestionReceived({pollId, id}));
    } catch (error) {
        dispatch(deleteQuestionRequestFiled(error.message));
    }
}

export const moveQuestion = (direction, pollId, id) => async (dispatch, getState) => {
    await dispatch(questionMoved({direction, pollId, id}));
    updateQuestionsSort(pollId, getState().questions.polls[pollId].entities);
}

export const updateQuestion = (pollId, data) => async (dispatch, getState) => {
    dispatch(updateQuestionRequested());
    try {
        const {content} = await questionsService.update(pollId, data.id, data);
        dispatch(updateQuestionReceived({pollId, data}));
    } catch (error) {
        dispatch(updateQuestionRequestFiled(error.message));
    }
}

export const getQuestions = (pollId) => (state) => {
    return state.questions.polls[pollId]?.entities
};

export const getQuestionsLoadingStatus = () => (state) => state.questions.isLoading;
export const getQuestionsSavingCount = () => (state) => state.questions.savingCount;

export const getPollById = (uuid) => (state) => {
    if (state.questions.entities) {
        return state.questions.entities.find((item) => item.uuid === uuid)
    }
    return undefined
}
export default questionsReducer