import httpService from './httpService'

const pollEndPoint = 'poll/'

const pollsService = {
    update: async (id, content) => {
        const { data } = await httpService.post(pollEndPoint + id, content)
        return data
    },
    get: async (id) => {
        const { data } = await httpService.get(pollEndPoint + id)
        return data
    },
    fetchAll: async () => {
        const { data } = await httpService.get(pollEndPoint)
        return data
    },
    create: async (content) => {
        const { data } = await httpService.post(pollEndPoint, content)
        return data
    },
    delete: async (id) => {
        const { data } = await httpService.delete(pollEndPoint + id)
        return data
    }
}

export default pollsService