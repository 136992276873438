import React, {useEffect} from "react"
import {useParams} from "react-router-dom";
import Breadcrumbs from "../../components/breadcrumbs";
import PollMenu from "../../components/pollMenu";
import {useDispatch, useSelector} from "react-redux";
import {getPollById} from "../../store/polls";
import PollHeader from "../../components/poll/pollHeader";
import {createQuestion, getQuestions, loadQuestionsList} from "../../store/questions";
import PollQuestion from "../../components/poll/pollQuestion";

const PollEdit = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const poll = useSelector(getPollById(params.pollId));
    const questions = useSelector(getQuestions(params.pollId));
    useEffect(() => {
        dispatch(loadQuestionsList(params.pollId));
    }, [params.pollId]);

    const createQuestionHandler = (index) => {
        dispatch(createQuestion(params.pollId, index))
    }

    return (
        <>
            <Breadcrumbs items={[{title: "Главная", url: "/"}, {
                title: "Мои опросы",
                url: "/polls/"
            }, {title: poll?.name}]}></Breadcrumbs>
            <PollMenu pollId={params.pollId}/>

            <div className="row">
                <PollHeader pollId={params.pollId}/>
                <div className="col-12 text-center my-2">
                    <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon"
                            onClick={() => createQuestionHandler(0)}>
                        <i className="mdi mdi-plus"></i>
                    </button>
                </div>
                {questions && questions.map((item, index) =>
                    <React.Fragment key={item.id}>
                        <PollQuestion question={item} pollId={params.pollId}></PollQuestion>
                        <div className="col-12 text-center my-2">
                            <button type="button" className="btn btn-outline-secondary btn-rounded btn-icon"
                                    onClick={() => createQuestionHandler(index + 1)}>
                                <i className="mdi mdi-plus"></i>
                            </button>
                        </div>
                    </React.Fragment>)}

            </div>
        </>

    );
}

export default PollEdit;