import React, {useEffect} from "react"
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getPollById, loadPollById} from "../../store/polls";
import {getQuestions, loadQuestionsList} from "../../store/questions";
import {getPublishedPoll, loadPublishedPollById} from "../../store/published";

const PollPublished = () => {
    const dispatch = useDispatch();
    const params = useParams();
    useEffect(() => {
        dispatch(loadPublishedPollById(params.pollId));
    }, [params.pollId]);

    const {poll, questions} = useSelector(getPublishedPoll());

    if (!poll || !questions) return "Loading...";

    console.log(questions);
    return (
        <>
            <div className="col-md-12">
                <div className="card grid-margin">
                    <div className="card-body">
                        <p className="card-title">{poll.name}</p>
                        <p className="mb-3 text-muted">{poll.description}</p>
                    </div>
                </div>
                {questions.map(item => <div className="card grid-margin" key={item.id}>
                    <div className="card-body">
                        <p className="card-title">{item.name}</p>
                        <p className="mb-3 text-muted">{item.description}</p>
                    </div>
                </div>)}
            </div>
        </>
    );
}

export default PollPublished;