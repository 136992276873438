import axios from 'axios';
import configFile from '../config';

const http = axios.create({
    baseURL: configFile.apiEndPoint,
    withCredentials: true,
})

http.interceptors.request.use(
    async (config) => {
        try {
            return config;
        } catch (e) {
            return Promise.reject(e);
        }
    },
    (error) => {
        return Promise.reject(error);
    }
)

http.interceptors.response.use(
    (res) => {
        return res;
    },
    (error) => {
        if (error?.response?.status === 401) {
            window.location.href = configFile.authPage;
        }
        const expectedErrors =
            error.response &&
            error.response.status >= 400 &&
            error.response.status < 500
        if (!expectedErrors) {
            console.log('Unexpected error');
        }
        return Promise.reject(error);
    }
)

const httpService = {
    get: http.get,
    post: http.post,
    put: http.put,
    delete: http.delete
}
export default httpService