import React from "react"
import Breadcrumbs from "../../components/breadcrumbs";
import {useParams} from "react-router-dom";
import PollMenu from "../../components/pollMenu";
import {useSelector} from "react-redux";
import {getPollById} from "../../store/polls";

const PollReport = () => {
    const params = useParams();
    const poll = useSelector(getPollById(params.pollId));
    return (
        <>
            <Breadcrumbs
                items={[{title: "Главная", url: "/"}, {title: "Мои опросы", url: "/polls/"}, {title: poll?.name}]
                }></Breadcrumbs>
            <PollMenu pollId={params.pollId}/>
        </>
    );
}

export default PollReport;