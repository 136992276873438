import {createSlice} from '@reduxjs/toolkit'
import questionTypesService from '../services/questionTypesService'

const questionTypesSlice = createSlice({
    name: 'questionTypes',
    initialState: {
        entities: [],
        error: null,
        isLoading: false,
        lastFetch: null,
    },
    reducers: {
        questionTypesRequested: (state, action) => {
            state.isLoading = true;
        },
        questionTypesReceived: (state, action) => {
            state.entities = action.payload;
            state.lastFetch = Date.now();
            state.isLoading = false;
        },
        questionTypesRequestFiled: (state, action) => {
            state.error = action.payload;
        },

    }
})
const {reducer: questionTypesReducer, actions} = questionTypesSlice
const {
    questionTypesRequested,
    questionTypesReceived,
    questionTypesRequestFiled,
} = actions

function isOutdated(date) {
    date = date || null;
    if (Date.now() - date > 60 * 60 * 100) return true
    return false
}

export const loadQuestionTypesList = () => async (dispatch, getState) => {
    const {lastFetch, isLoading} = getState().questionTypes;
    if (isOutdated(lastFetch) && !isLoading) {
        dispatch(questionTypesRequested());
        try {
            const {content} = await questionTypesService.fetchAll();
            dispatch(questionTypesReceived(content));
        } catch (error) {
            dispatch(questionTypesRequestFiled(error.message));
        }
    }
}

export const getQuestionTypes = () => (state) => {
    return state.questionTypes.entities
};

export const getQuestionTypesLoadingStatus = () => (state) => state.questionTypes.isLoading;

export const getQuestionTypeById = (id) => (state) => {
    if (state.questions.entities) {
        return state.questionTypes.entities.find((item) => item.id === id)
    }
    return undefined
}
export default questionTypesReducer