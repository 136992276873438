import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPollById, updatePoll} from "../../store/polls";
import {Field, Form, Formik} from "formik";
import * as yup from 'yup';
import AutoSubmit from "../base/autoSubmit";
import {useDebounce} from "../../hooks/useDebounce";

const PollHeader = ({pollId}) => {
    const dispatch = useDispatch();
    const poll = useSelector(getPollById(pollId));

    const debouncedSaveHandler = useDebounce((data) => {
        console.log('Сохранение формы');
        dispatch(updatePoll({...data, uuid: pollId}));
    });

    if (!poll) return 'Loading...'

    const signInSchema = yup.object().shape({
        name: yup.string().required('Поле обязательное для заполнения')
    });

    const formData = {
        name: poll.name || "",
        description: poll.description || "",
    }

    return <div className="col-12 stretch-card">
        <div className="card">
            <div className="card-body">
                <p className="card-title">Шапка</p>
                <p className="mb-3 text-muted"></p>
                <Formik
                    initialValues={formData}
                    validationSchema={signInSchema}
                    onSubmit={debouncedSaveHandler}>
                    {({errors: formicErrors, touched}) => <Form>
                        <div className="form-group">
                            <label htmlFor="pollName">Заголовок</label>
                            <Field
                                name="name"
                                type="text"
                                className="form-control"
                                id="pollName"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="pollDescription">Описание</label>
                            <Field
                                as="textarea"
                                name="description"
                                className="form-control"
                                rows="4"
                                id="pollDescription"
                            />
                        </div>
                        <AutoSubmit/>
                    </Form>}
                </Formik>
            </div>
        </div>
    </div>
}

export default PollHeader;