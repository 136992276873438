import React from "react";
import {Link} from "react-router-dom";

const Breadcrumbs = ({items}) => {
    return (<div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center">
            <div className="d-none d-md-flex">
                {items.map((item, index) => {
                    return <React.Fragment key={item.title + item.url}>
                        <p className="text-muted mb-0 tx-13 cursor-pointer">
                            {item.url && <Link to={item.url}>{item.title}</Link>}
                            {!item.url && item.title}
                        </p>
                        {index < items.length - 1 && <i className="mdi mdi-chevron-right text-muted"></i>}
                    </React.Fragment>
                })}
            </div>
        </div>
    </div>);
}

export default Breadcrumbs;